import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['glider','card']

  connect() {
    this.loaded = false
    let elements = document.querySelectorAll(
      'input.filled'
    );
    elements.forEach(el => {
      this.realUpdate(el);
    });
   
    if (this.hasGliderTarget) {
      let view = document.querySelector('.radio-card-carousel').dataset.preview
      let viewCard = view == 'true' ? 1.2 : 3.2
      this.initGlider(this.gliderTarget, viewCard)
    }
    this.loaded = true
  }

  update() {
    this.realUpdate(event.target);
    event.target.classList.add('filled')
  }

  updateSelect() {
    this.realUpdate(event.target.options[event.target.selectedIndex]);
    event.target.classList.add('filled')
  }

  realUpdate(element) {
    let disablers = JSON.parse(element.dataset.disablerActs);
    let enablers = JSON.parse(element.dataset.enablerActs);
    disablers.forEach(el => {
      let selector = this.evaluate_acc(el)
      let elems = this.findElem(selector)
      if(elems)
        elems.forEach(elem => {
          this.disable_element(elem)
        })
    })
    enablers.forEach(el => {
      let selector = this.evaluate_acc(el)
      let elems = this.findElem(selector)
      if(elems) {
        elems.forEach(elem => {
          if(elem.classList.contains('preview-element')) {
            elem.parentElement.childNodes.forEach(child => {
              if(child !== elem) {
                child.classList.add('dn')
              }
            })
          }
          this.enable_element(elem)
        })
      }
    })
  }

  findElem(selector) {
    if (!selector) return
    return document.querySelectorAll(selector)
  }

  disable_element(elem) {
    if (!elem) return
    if (elem.classList.contains('dn')) return
    elem.classList.add('dn')

    if(elem.localName == 'option') {
      if(elem.value == elem.parentNode.value)
        elem.parentNode.value = ''
      return
    }
    let question = elem.querySelectorAll('[data-required="true"]')[0]
    if (question)
      question.setAttribute("data-required", "false")
    let inputs = elem.getElementsByTagName('input')
    for (let ee of inputs) {
      if (this.loaded) {
        if (ee.type == 'radio') {
          ee.checked = false
        } else {
          ee.value = ''
        }
      }
      let questions = elem.querySelectorAll('[data-required="true"]')
      questions.forEach(q => { q.setAttribute("data-required", "false") });
    }
  }


  enable_element(elem) {
    if (!elem) return
    elem.classList.remove('dn')
    if(elem.glider) elem.glider.update()
    let questions = elem.querySelectorAll('[data-required]')
    questions.forEach(q => { q.setAttribute("data-required", "true") });
  }

  evaluate_acc(el) {
    let keys = el.split(':')
    if (keys[1] && !this.isSelected(keys[1])) return undefined
    return '.' + keys[0] + '-wrapper'
  }

  isSelected(el) {
    if (!el) return
    let input = document.querySelector('.' + el)
    if (input !== null && input.checked) return true
    return false
  }

  checkValue(element) {
    let identifier = element.dataset.identifier
    let valid = false
    let elems = [element]
    if (identifier !== undefined) {
      elems = element.querySelectorAll(identifier)
    }
    if (elems.length == 0) return false;

    switch (elems[0].type) {
      case 'radio':
        elems.forEach(rr => { valid = valid || rr.checked })
        break;
      case 'select-one':
      case 'text':
        valid = true
        elems.forEach(rr => { valid = valid && rr.value != '' })
        break;
      case 'number':
        valid = true
        elems.forEach(rr => { valid = valid && rr.value != '' })
        break;
      case 'tel':
        valid = true
        elems.forEach(rr => { valid = valid && rr.value != '' })
        break;
      case 'email':
        let regex = /^[-\w.%+]{1,64}@(?:[A-Z0-9-]{1,63}\.){1,125}[A-Z]{2,63}$/i;
        valid = true
        elems.forEach(rr => { valid = valid && regex.test(rr.value) })
        break;
    }
    if (valid) {
      element.classList.remove('error')
    } else {
      element.classList.add('error')
    }
    return valid
  }

  validateStep(event) {
    let elements = document.querySelectorAll('[data-required="true"]')
    let step_valid = true
    let error_valid = ''

    elements.forEach(el => {
      let el_valid = this.checkValue(el) // Required to paint
      step_valid = step_valid && el_valid
    })

    error_valid = document.querySelectorAll('div.error')
    if (error_valid.length != 0) {
      error_valid[0].scrollIntoView({ block: "center", behavior: "smooth" })
    }
    if (!step_valid) event.preventDefault()
    return step_valid
  }


  // Glider

  initGlider(target, view_card) {
    let wrapper = target.closest('.question-wrapper')
    wrapper.glider = new Glide(target, {
      type: 'slider',
      bound: true,
      rewind: false,
      startAt: 0,
      perView: view_card,
      gap: 10,
      peek: {
        before: 50,
        after: 50
      },
      breakpoints: {
        960: { perView: 2 },
        480: { perView: 1.2 },
      }
    })

    wrapper.glider.on('run', () => {
      this.hideForwardButton(wrapper)
      this.hidePrevButton(wrapper)
    })

    wrapper.glider.mount()
  }

  hidePrevButton(target) {
    let prevButton = target.getElementsByClassName('glide__arrow--left')
    if (target.glider._c.Run.isStart() == true) {
      prevButton[0].style.visibility = 'hidden'
    } else {
      prevButton[0].style.visibility = ''
    }
  }

  hideForwardButton(target) {
    let forwardButton = target.getElementsByClassName('glide__arrow--right')
    if (target.glider._c.Run.isEnd() == true) {
      forwardButton[0].style.visibility = 'hidden'
    } else {
      forwardButton[0].style.visibility = ''
    }
  }

  //info cards

  selectedCardStyles(event) {
    this.cardTargets.forEach((item) => {
      if(item.querySelector('input').checked){
        item.querySelector('label.bg-blue-info-cards').style.backgroundColor = '#1e85e6'
        item.classList.remove('shadow-4')
        item.classList.add('shadow-6')
      } else {
        item.querySelector('label.bg-blue-info-cards').style.backgroundColor = ''
        item.classList.add('shadow-4')
        item.classList.remove('shadow-6')
      }
    })
  }
}
