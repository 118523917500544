import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['elementShow','elementHidden']

  connect() {
  }

  show() {
    this.elementShowTarget.classList.add('dn')
    this.elementHiddenTarget.classList.remove('dn')
  }

  hidden() {
    this.elementShowTarget.classList.remove('dn')
    this.elementHiddenTarget.classList.add('dn')
  }
}
