import { Controller } from "stimulus"

export default class extends Controller {

  accept_cookies(event) {
    var twenty_years = (60 * 60 * 24 * 365 * 20) + ";"
    document.cookie = "accept_cookies=allowed;max-age=" + twenty_years
    this.element.classList.add('o-0')
    setTimeout(() => { this.element.classList.add('dn') },500)
  }

  acceptCookiesOnScroll = () => {
    this.accept_cookies()
    this.element.removeAttribute('data-action')
  }
}
