import { Controller } from "stimulus"


export default class extends Controller {
  static targets = ['next_step', 'inputs_wrapper']

  connect() {
    const btnModal = document.querySelector("a.next-button");
    this.searchFields();
    this.buttonValidateStep();
    if (btnModal != null) {
      btnModal.addEventListener("click", () => {
        this.next_stepTarget.classList.remove('disable-validate-step');
      })
    }
  }

  buttonValidateStep(event) {
    console.log("valor", event)
    let elements = document.querySelectorAll('[data-required="true"]');
    let statusBtn = [];
    const itsTrue = (currentValue) => currentValue;

    elements.forEach(element => {
      let identifier = element.dataset.identifier
      let valid = false
      let elems = element.querySelectorAll(identifier)
      switch (identifier) {
        case 'input[type="radio"]':
          elems.forEach(rr => { valid = valid || rr.checked })
          break;
        case 'select':
        case 'input[type="number"]':
          elems.forEach(rr => { valid = valid || rr.value != '' })
          break;
      }
      statusBtn.push(valid)
    })

    if (statusBtn.every(itsTrue)) {
      this.next_stepTarget.classList.remove('disable-validate-step')
    } else {
      this.next_stepTarget.classList.add('disable-validate-step')
    }
  }

  searchFields() {
    const _this = this;

    for (const select of this.element.querySelectorAll('select,input,.inputs_wrapper')) {
      select.addEventListener('change', function (e) { _this.buttonValidateStep(e) });
    }

    for (const select of this.element.querySelectorAll('div.minus, div.plus')) {
      select.addEventListener('click', function (e) { _this.buttonValidateStep(e) });
    }
  }
}
