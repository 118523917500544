import { Controller } from "stimulus"

export default class extends Controller {

  handleMinus(event) {
    var data = event.target.dataset
    var dataset = document.querySelector('#'+data.target).dataset

    if (data.step == "1") {
      var value = parseInt(document.querySelector('#'+data.target).value);
      value--;
    } else {
      var value = parseFloat(document.querySelector('#'+data.target).value);
      value = Math.round(( (value - 0.01) + Number.EPSILON) * 100.0) / 100.0;
    }

    this.changeValue(dataset, value, event.target)
  }

  handlePlus(event) {
    var data = event.target.dataset
    var dataset = document.querySelector('#'+data.target).dataset

    if (data.step == "1") {
      var value = parseInt(document.querySelector('#'+data.target).value);
      value++;
    } else {
      var value = parseFloat(document.querySelector('#'+data.target).value);
      value = Math.round(( (value + 0.01) + Number.EPSILON) * 100) / 100;
    }

    this.changeValue(dataset, value, event.target)
  }

  handleChange(event) {
    var dataset = event.target.dataset
    var value = parseFloat(document.querySelector('#'+dataset.target).value);
    this.changeValue(dataset, value, event.target)
  }

  changeValue(dataset, value, target) {
    if (isNaN(value)) {
      document.querySelector('#'+dataset.target).value = dataset.min;
      document.querySelector('#info_'+dataset.target).innerHTML = 'El valor debe ser un número'
      document.querySelector('#info_'+dataset.target).classList.remove('dn')
    } else if(dataset.max && value > dataset.max) {
      document.querySelector('#'+dataset.target).value = dataset.max
      document.querySelector('#info_'+dataset.target).innerHTML = 'El valor máximo es de ' + dataset.max + ''
      document.querySelector('#info_'+dataset.target).classList.remove('dn')
    } else if(dataset.min && value < dataset.min) {
      document.querySelector('#'+dataset.target).value = dataset.min
      document.querySelector('#info_'+dataset.target).innerHTML = 'El valor mínimo es de ' + dataset.min + ''
      document.querySelector('#info_'+dataset.target).classList.remove('dn')
    } else {
      document.querySelector('#'+dataset.target).value = value;
      document.querySelector('#info_'+dataset.target).innerHTML = ''
      document.querySelector('#info_'+dataset.target).classList.add('dn')
    }
    window.dispatchEvent(new CustomEvent("input-change", { detail: { origin: target } }))
  }
}
