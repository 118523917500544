import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["card"]

  redirect(event) {
    if (event.target.id != "deleteButton") {
      location.href = event.currentTarget.dataset.url
    }
  }
}
