function OnInit() {      
  // Cargador en formularios calculadora
  if(window.location.pathname.includes('/calcula/ampliaciones')
    || window.location.pathname.includes('/calcula/elementos')
    || window.location.pathname.includes('/calcula/casa')) {
      $('.next-button, .house-next-btn').unbind( "click" );
      $('.next-button, .house-next-btn').click(function(e) {
        setTimeout(function() {
          if($('div.error').length === 0) {
              console.log("show loader")
              $('.imgLoader').show();
            } 
          }, 100);
      });
    }
  }
  
  setTimeout(OnInit, 1000);
  
  
  var send = XMLHttpRequest.prototype.send;
  XMLHttpRequest.prototype.send = function(data) {
    $('.imgLoader').show();
    send.call(this, data);
  }
  
  var oldXHROpenInit = window.XMLHttpRequest.prototype.open;
  window.XMLHttpRequest.prototype.open = function(method, url, async, user, password) {
    $('.imgLoader').show();
    this.addEventListener('load', function() {
      $('.imgLoader').hide();
      setTimeout(OnInit, 100);
    });
    return oldXHROpenInit.apply(this, arguments);
  }