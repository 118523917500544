import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['choice']

  connect() {
    this.showSelectedCard(this.choiceTarget.value)
  }

  show(e){
    this.showSelectedCard(e.target.value)
  }

  showSelectedCard(value) {
    let cards = document.getElementsByClassName('dropdown-card')
    for (let card of cards) {
      if (card.classList.contains(value)) {
        card.style.display = 'flex'
      } else {
        card.style.display = 'none'
      }
    }
  }
}
