import { Controller } from "stimulus"

export default class extends Controller {

  static targets = ["selectButton", "radioCard", "glider", "modal"]

  connect() {
    this._selected_card = undefined
    this._selected_slider_id = undefined
    this._current_selected_card = undefined
    this._glider = undefined

    this.initGlider()
  }

  openModal() {
    this.modalTarget.style.visibility = 'visible'
  }

  closeModal() {
    this.modalTarget.style.visibility = 'hidden'
  }

  initGlider() {
    let viewCard = this.data.get('viewcard') ? this.data.get('viewcard') : 2;

    if (this.hasGliderTarget) {
      this._glider = new Glide(this.gliderTarget, {
        type: 'slider',
        bound: true,
        rewind: false,
        startAt: 0,
        perView: viewCard,
        gap: 0,
        breakpoints: {
          960: {
            perView: 2,
          },
          480: {
            perView: 1.2,
          }
        }
      })
      this._glider.mount()
      this._glider.on('run', () => {
        this.hideForwardButton()
        this.hidePrevButton()
      })
    }
  }

  hidePrevButton() {
    let prevButton = document.getElementsByClassName('glide__arrow--left')
    if (this._glider._c.Run.isStart() == true) {
      prevButton[0].style.visibility = 'hidden'
    } else {
      prevButton[0].style.visibility = ''
    }
  }

  hideForwardButton() {
    let forwardButton = document.getElementsByClassName('glide__arrow--right')
    if (this._glider._c.Run.isEnd() == true) {
      forwardButton[0].style.visibility = 'hidden'
    } else {
      forwardButton[0].style.visibility = ''
    }
  }

  selectCard() {
    this.radioCardTargets.forEach(card => { card.checked = false })
    var slider_to_go = 0
    if (this._selected_card === undefined) {
      this.selectButtonTarget.classList.add('disabled')
    } else {
      slider_to_go = this._selected_slider_id > 0 ? this._selected_slider_id - 1 : 0
      this._selected_card.checked = true
      this.selectButtonTarget.classList.remove('disabled')
    }
    this._glider.go(`=${slider_to_go}`)
  }

  removeSelections() {
    if (this._selected_card !== undefined) {
      this.clearSelection()
    }
  }

  loadDataFromCurrentCard(event) {
    let card = event.target
    this._current_selected_card = card
    this.selectButtonTarget.classList.remove('disabled')
  }

  clearSelection() {
    this.radioCardTargets.forEach(card => { card.checked = false })
    this.selectButtonTarget.classList.add('disabled')
    this._current_selected_card = undefined
    this._selected_card = undefined
    this._selected_slider_id = undefined
  }

  handleChange(event) {
    this.removeSelections()
  }

  persistSelection() {
    this._selected_card = this._current_selected_card
    this._selected_slider_id = this._selected_card.dataset.sliderArrayId
    this.setValues()
    this.removeErrorMessages()
  }

  setValues() {
    let values = JSON.parse(this._selected_card.dataset.valueSetters)
    values.forEach(val => {
      let element = document.getElementById(`${val[0]}-wrapper`)
      element.querySelector('input').value = val[1]
    });
  }

  removeErrorMessages() {
    let errorMessages = document.getElementsByClassName('error')
    for (let i = 0; i < errorMessages.length; i++) {
      if (errorMessages[i].classList.contains('info-message')) {
        errorMessages[i].classList.add('dn')
      }
    }
  }

  readMore(event) {
    let action = event.target.dataset.moreinfo;
    let showAdvDis = document.querySelector('#desplegar-' + event.target.dataset.id);
    const objShow = {
      mas: { method: 'remove', text: 'Leer Menos', info: 'menos' },
      menos: { method: 'add', text: '...Leer Más', info: 'mas' }
    }

    objShow[action].method == 'remove' ? showAdvDis.classList.remove('dn') : showAdvDis.classList.add('dn');
    event.target.textContent = objShow[action].text;
    event.target.dataset.moreinfo = objShow[action].info;

  }

  nextStepModal(){
    let btn = document.querySelector('.next-button')
    btn.click()
  }
}
