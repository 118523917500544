import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['tabContainer', 'tab']

  connect() {
    this.initTab()
  }

  showTab(event) {
    let ContainerTabs = this.tabContainerTargets
    let selTab = event.target
    let tabs = this.tabTargets

    tabs.forEach(item => {
      item.classList.remove('active')
    })

    ContainerTabs.forEach(element => {
      element.classList.add('dn')
      element.classList.remove('active')
      if (element.dataset.tab == selTab.dataset.tab) {
        element.classList.remove('dn')
        element.classList.add('active')
        selTab.classList.add('active')
      }
    });
  }

  initTab() {
    let ContainerTabs = this.tabContainerTargets
    let tabs = this.tabTargets

    ContainerTabs.forEach(element => {
      element.classList.add('dn')
    })
    tabs[0].classList.remove('active')
    tabs[0].classList.add('active')
    ContainerTabs[0].classList.remove('dn')
    ContainerTabs[0].classList.add('active')
  }
}
