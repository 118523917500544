import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["modal"]

  connect(){
    this.body = document.querySelector('body')
  }

  openModal() {
    this.modalTarget.style.display = 'flex'
    this.body.classList.add('overflow-y-hidden')
  }

  closeModal() {
    this.modalTarget.style.display = 'none'
    this.body.classList.remove('overflow-y-hidden')
  }
}
