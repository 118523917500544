import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['unconcludedModal', 'exitButton']

  openModal(event) {
    let identifier = event.currentTarget.dataset.modalId
    this[identifier + 'Target'].style.display = 'flex';
  }

  closeModal(event) {
    let identifier = event.currentTarget.dataset.modalId
    this[identifier + 'Target'].style.display = 'none';
  }

  notifyProgressLoss(event) {
    if(this.hasUnconcludedModalTarget) {
      event.preventDefault()
      event.stopPropagation()
      this.unconcludedModalTarget.style.display = 'flex'
      this.exitButtonTarget.href = event.target.href
      return false
    } else {
      return true
    }
  }
}
