import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['tabContainer', 'tab', 'glideArrowLeft', 'glideArrowRight']
  glide_instances = {}
  current_tab = null

  connect() {
    this.initTab()
    this.update_metadata_user_interactions(
      'all', 'tab_review', 'proyecto completo', document.querySelector('.dataset').dataset.visitId
    )
  }

  showTab(event) {
    let containerTabs = this.tabContainerTargets
    let selTab = event.target.parentElement.dataset.tab ? event.target.parentElement : event.target
    let tabs = this.tabTargets
    tabs.forEach(item => {
      item.classList.remove('active')
    })
    this.showBudgetForStage(selTab.dataset.tab);
    containerTabs.forEach(element => {
      element.classList.add('dn')
      element.classList.remove('active')
      if (element.dataset.tab == selTab.dataset.tab) {
        element.classList.remove('dn')
        element.parentElement.classList.add('active')
        selTab.parentElement.classList.add('active')
        this.current_tab = element.dataset.tab
        this.initGlide(element)
      }
    });
  }

  initTab() {
    let containerTabs = this.tabContainerTargets
    let tabs = this.tabTargets

    containerTabs.forEach(element => {
      element.classList.add('dn')
    })
    tabs[0].classList.remove('active')
    tabs[0].classList.add('active')
    containerTabs[0].classList.remove('dn')
    containerTabs[0].classList.add('active')
    this.initGlide(containerTabs[0])
    this.current_tab = containerTabs[0].dataset.tab
  }

  initGlide(element){
    if(this.glide_instances[element.dataset.tab] != null){
      this.glide_instances[element.dataset.tab].destroy();
    }
    let glide = new Glide(`.glide-${element.dataset.tab}`, {
      type: 'slider',
      rewind: false,
      bound: false,
      startAt: 0,
      perView: 1,
      peek: 0
    })
    let leftBtn = document.querySelector(`[data-btn="left-${element.dataset.tab}"]`);
    let rightBtn = document.querySelector(`[data-btn="right-${element.dataset.tab}"]`);
    leftBtn.classList.add('dn');
    rightBtn.classList.remove('dn');
    let _this = this
    glide.on(['run'], () => {
      let leftBtn = document.querySelector(`[data-btn="left-${_this.current_tab}"]`);
      let rightBtn = document.querySelector(`[data-btn="right-${_this.current_tab}"]`);
      
      if(glide._c.Run.isStart() == true){
        leftBtn.classList.add('dn');
      }else{
        leftBtn.classList.remove('dn');
      }

      if(glide._c.Run.isEnd() == true){
        rightBtn.classList.add('dn');
      }else{
        rightBtn.classList.remove('dn');
      }

      let key = ''
      if(glide.selector == '.glide-proyecto-completo') {
        key = 'all'
      } else {
        key = glide.selector.replace('.glide-etapa-', '')
      }

      let target = ''
      switch (glide._i) {
        case 0:
          target = 'tab_review'
          break
        case 1:
          target = 'tab_materials'
          break
        case 2:
          target = 'tab_activities'
          break
        case 3:
          target = 'tab_blueprints'
          break
      }

      let name = glide.selector.replace('.glide-', '')
      let visit = document.querySelector('.dataset').dataset.visitId
      this.update_metadata_user_interactions(key, target, name, visit)
    });
    glide.mount()
    this.glide_instances[element.dataset.tab] = glide
  }

  update_metadata_user_interactions(key, target, name, visit) {
    let url = document.querySelector('.dataset').dataset.urlmetadatum
    const token = document.getElementsByName("csrf-token")[0].content
    let data = {}

    data['context_key'] = key
    data['context_target'] = target
    data['full_name'] = name
    data['visit_id'] = visit

    fetch(url, {
      method: 'POST',
      body: JSON.stringify(data),
      headers: { 'Content-Type': 'application/json','X-CSRF-Token': token },
    })
    .then((response) => {
    })
    .catch(error => console.log(error))
  }

  showBudgetForStage(stage){
    document.querySelectorAll('[data-budget-container]').forEach((budget) => {
      budget.classList.add('dn');
    })
    document.querySelector(`[data-budget-container="${stage}"]`).classList.remove('dn')
  }
}
