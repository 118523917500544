import { Controller } from "stimulus"

function get_pros(pros){
  var html = '';
  pros.forEach((pro) => {
    html += `<li class="flex mt1"><span class="bg-blue w1 h1 br-100 mr1 pro-bullet" style="flex-shrink:0"></span><span class="f7">${pro}</span></li>`;
  })
  return html;
}

function get_cons(cons){
  var html = '';
  cons.forEach((con) => {
    html += `<li class="flex mt1"><span class="bg-red w1 h1 br-100 mr1 con-bullet" style="flex-shrink:0"></span><span class="f7">${con}</span></li>`;
  })
  return html;
}

export default class extends Controller {
  static targets = ["radio_button_modal", "modal_information"]

  open_modal(event) {
    let event_target = null;
    if (event.target.id == 'more-info') {
      event_target = event.target;
    } else {
      event_target = event.target.parentElement;
    }

    var option_information = null;
    if (event_target.dataset.value[0] == '\'') {
      option_information = JSON.parse(event_target.dataset.value.substring(1,event_target.dataset.value.length-1))
    } else {
      option_information = JSON.parse(event_target.dataset.value)
    }
    
    this.radio_button_modalTarget.style.display = 'flex';
    if (option_information['Espesores'] !== undefined) {
      document.querySelector('#measure_thickness_title').innerHTML = 'Espesores';
      document.querySelector('#measure_thickness').innerHTML = option_information['Espesores'];
    } else if (option_information['Medidas'] !== undefined) {
      document.querySelector('#measure_thickness_title').innerHTML = 'Medidas';
      document.querySelector('#measure_thickness').innerHTML = option_information['Medidas'];
    }
    if (option_information['Resistencia'] !== undefined) {
      document.querySelector('#resistance').innerHTML = option_information['Resistencia'];
    } else {
      document.querySelector('#resistance_title').classList.add('dn')
    }
    document.querySelector('#title').innerHTML = event_target.dataset.title;
    document.querySelector('#optionImage').src = event_target.dataset.image; 
    document.querySelector('#optionImageMobile').src = event_target.dataset.image_mobile; 
    document.querySelector('#uses').innerHTML = option_information['Usos'];
    document.querySelector('#pros').innerHTML = get_pros(option_information['Ventajas']);
    document.querySelector('#cons').innerHTML = get_cons(option_information['Desventajas']);
  }

  close_modal(event) {
    this.radio_button_modalTarget.style.display = 'none';
  }
}
