import { Controller } from "stimulus"
import Choices from 'choices.js'
import Rails from '@rails/ujs'
import Api from '../../api/base'

export default class extends Controller {
  static targets = [
    'selectize_city', 'selectize_state', 'city', 'solution', 'inputs_wrapper',
    'next_step', 'next_container', 'initial_container', 'initial_buttons', 'step_buttons'
  ]

  initGeoSelectors() {
    const _this = this
    const selectizeState = new Choices(this.selectize_stateTarget,
      { itemSelectText: '', position: 'bottom', removeItemButton: true, noResultsText: 'No hay resultados' }
    )
    const selectizeCity = new Choices(this.selectize_cityTarget,
      { itemSelectText: '', position: 'bottom', removeItemButton: true, noResultsText: 'No hay resultados' }
    )

    this.selectize_stateTarget.addEventListener(
      'change',
      function (event) {
        _this.getCities(selectizeCity, event)
      },
      false
    )

    if (this.selectize_stateTarget.value) {
      this.getCities(selectizeCity, { detail: { value: this.selectize_stateTarget.value } }, false)
    }
  }

  getCities(selectizeCity, event, stateEvent = true) {
    let labelCity = document.querySelector('[id="city_tag_label"]')
    selectizeCity.clearChoices()
    var api = new Api('v1/city_catalogs/state_cities')
    api.all(function (results) {
      results = results.map(x => ({ value: x.id, label: x.name }))
      results.unshift(({ value: '', label: '' }))
      selectizeCity.setChoices(
        results,
        'value',
        'label',
        false,
      );
      selectizeCity.setChoiceByValue(stateEvent ? '' : parseInt(this.cityTarget.value))
      labelCity.innerText = event.detail.value == 9 ? 'Alcaldía' : 'Ciudad/Municipio'
    }.bind(this),
      function (result, error) { console.log(error) },
      { state_id: event.detail.value }
    )
  }

  connect() {
    if(this.hasSelectize_stateTarget) { this.initGeoSelectors() }
    this.preselectedSolution()
  }

  loadStep(event) {
    let target = event.target
    this.next_stepTarget.classList.add('disable-validate-step')
    this.inputs_wrapperTarget.innerHTML = ''
    Rails.ajax({
      type: "get",
      url: target.dataset.url + target.value,
      success: this.swapInputs.bind(this)
    })
  }

  swapInputs(response) {
    this.inputs_wrapperTarget.innerHTML = response.html;
    this.next_stepTarget.classList.remove('dn')
  }

  focusSelect(event){
    let inputCombo = event.currentTarget.querySelector('.choices__input.choices__input--cloned');
    let classActive = event.currentTarget.querySelector('.choices__list.choices__list--dropdown');
    event.stopPropagation();
    classActive.classList.add("is-active");
    inputCombo.blur();
    inputCombo.focus();
  }

  validateStepInitial(event) {
    event.preventDefault()
    let error_valid = this.initial_containerTarget.querySelectorAll('div.error')
    if (error_valid.length != 0) {
      this.hideContainer(event)
    } else {
      let error = this.next_containerTarget.querySelectorAll('.error')
      this.initial_containerTarget.classList.add('dn')
      this.next_containerTarget.classList.remove('dn')
      this.initial_buttonsTarget.classList.add('dn')
      this.step_buttonsTarget.classList.remove('dn')
      error.forEach(item => item.classList.remove('error'))
      scroll({ top: 0});
    }
  }

  hideContainer(event) {
    event.preventDefault()
    this.initial_containerTarget.classList.remove('dn')
    this.next_containerTarget.classList.add('dn')
    this.initial_buttonsTarget.classList.remove('dn')
    this.step_buttonsTarget.classList.add('dn')
    scroll({ top: 0 });
  }

  preselectedSolution() {
    const urlParams = new URLSearchParams(window.location.search)
    const solutionId = urlParams.get('solution')
    let optionElement = ''
    if (solutionId == null || solutionId == '') return false

    optionElement = document.querySelector(`input[value="${solutionId}"`)
    optionElement != null ? (optionElement.checked = true, this.loadStep({target: optionElement})) : ''
  }
}
