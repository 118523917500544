import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['sliderOne', 'sliderTwo', 'progress', 'checkbox']

  connect() {
    this.paintValues()
    setTimeout(() =>{
      this.calculation()
    })
  }

  calculation() {
    this.paintProgress(this.sliderOneTarget.value, this.sliderTwoTarget.value)
  }

  paintValues() {
    let inputChecked = this.checkboxTargets.filter(item => item.classList.contains("filled"))
    let sliderOne = inputChecked[0]
    let sliderTwo = inputChecked[inputChecked.length - 1 ]
    if(inputChecked.length == 0){ return }

    this.paintProgress(sliderOne.dataset.value, sliderTwo.dataset.value)
    this.sliderOneTarget.value = sliderOne.dataset.value
    this.sliderTwoTarget.value = sliderTwo.dataset.value

  }

  paintProgress(sliderOne, sliderTwo) {
    this.progressTarget.style.width = "auto"
    if (sliderOne > sliderTwo) {
      this.progressTarget.style.right = 100 - (sliderOne / this.sliderOneTarget.max) * 100 + '%'
      this.progressTarget.style.left = (sliderTwo / this.sliderTwoTarget.max) * 100 + '%'
      this.updateCheckbox(sliderTwo, sliderOne)
    } else {
      this.progressTarget.style.left = (sliderOne / this.sliderOneTarget.max) * 100 + '%'
      this.progressTarget.style.right = 100 - (sliderTwo / this.sliderTwoTarget.max) * 100 + '%'
      this.updateCheckbox(sliderOne, sliderTwo)
    }
  }

  selectAll() {
    this.progressTarget.style.width = "100%"
    this.sliderOneTarget.value = this.sliderOneTarget.min
    this.sliderTwoTarget.value = this.sliderOneTarget.max
    this.progressTarget.style.left = 0
    this.progressTarget.style.right = 0

    this.checkboxTargets.forEach((element) => {
      element.click()
      element.checked = true
    })
  }

  updateCheckbox(sliderOne, sliderTwo) {
    let max = Math.max(sliderOne, sliderTwo)
    let min = Math.min(sliderOne, sliderTwo)
    let clickers = []
    this.checkboxTargets.forEach((element) => {
      let value = parseInt(element.dataset.value)
      if (value <= max && value >= min) {
        clickers.push(element)
        element.checked = true
      } else {
        element.checked = false
      }
    })
    clickers.forEach((element) => {
      var evt = document.createEvent("HTMLEvents");
      evt.initEvent("change", false, true);
      element.dispatchEvent(evt);
    })
  }
}
