import { Controller } from "stimulus"
export default class extends Controller {
  static targets = [""]

  connect() {
    this.init()
  }

  init(){
    this.glide = new Glide(this.element, {
      type: 'slider',
      bound: true,
      startAt: 0,
      perView: 3,
      gap: 16,
      breakpoints: {
        960:{ perView: 3 },
        480:{ perView: 1 }
      }
    }).mount()
  }
}