import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["modal"]

  connect(){
  }

  handlePhaseSelection(event) {
    const selectedPhase = event.target.dataset.value;
    Array.from(document.getElementsByClassName('phase-input')).forEach((input) => input.checked = false)
    for(let previousStage=1; previousStage<=selectedPhase; previousStage++){
      let input = document.querySelector(`[data-value='${previousStage}']`);
      input.checked = true 
    }
    event.target.form.submit();
  }
}
